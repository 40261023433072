

#root{
    width: 100vw;
    height: 100vh;
}
:root {
	--hue: 223;
	--bg: hsl(var(--hue),90%,90%);
	--fg: hsl(var(--hue),90%,10%);
	--primary: hsl(var(--hue),90%,50%);
	--trans-dur: 0.3s;
	--trans-timing: cubic-bezier(0.65,0,0.35,1);
	font-size: calc(16px + (24 - 16) * (100vw - 320px) / (2560 - 320));
}

.choix-container{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
	
}

main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 50px;
}
button{
    font: 1em/1.5 "DM Sans", sans-serif;
    font-weight: bolder;
    background-color: blue;
    color: white;
    padding: 1em 2em;
    width: 20em;
    border-radius: 5px;
    transition: background-color var(--trans-dur);
}

.choix-container svg{
    height: 50px;
    position: absolute;
    padding: 0;
    margin: 0;
    top: 10px;
    left: -50px;
}

.choix-container svg #loop-normal {
    fill: none;
    stroke: white;
    stroke-width: 12;
  }

  .choix-container svg #plug,
  .choix-container svg #socket {
    fill:skyblue;
  }

body,
input {
	color: var(--fg);
	font: 1em/1.5 "DM Sans", sans-serif;
}
body {
	background-color: var(--bg);
	display: flex;
	height: 100vh;
	transition:
		background-color var(--trans-dur),
		color var(--trans-dur);
}
main {
	margin: auto;
	padding: 1.5em 0;
	width: 100%;
}
.choix-container form {
	margin: auto;
	max-width: 20em;
	position: relative;
	width: calc(100% - 3em);
}
label,
input[type="radio"] {
	cursor: pointer;
	-webkit-tap-highlight-color: transparent;
}
label {
	background-color: hsla(0,0%,100%,0);
	border-radius: 0.75em;
	display: flex;
	align-items: center;
	padding: 1.5em;
	transition: background-color var(--trans-dur);
}
input[type="radio"] {
	background-color: hsl(0,0%,100%);
	border-radius: 50%;
	box-shadow:
		0 0 0 0.0625em hsl(var(--hue),90%,80%),
		0 0.125em 0.125em 0.0625em hsla(var(--hue),90%,10%,0.3);
	flex-shrink: 0;
	margin-inline-end: 1em;
	outline: transparent;
	position: relative;
	width: 1.5em;
	height: 1.5em;
	transition:
		background-color var(--trans-dur),
		box-shadow var(--trans-dur);
	-webkit-appearance: none;
	appearance: none;
}
input[type="radio"]:before,
input[type="radio"]:after {
	border-radius: 50%;
	content: "";
	display: block;
	position: absolute;
	transform: scale(0);
	transition: transform var(--trans-dur) var(--trans-timing);
}
input[type="radio"]:before {
	box-shadow:
		0 0 0 0.5em var(--primary) inset,
		0 0 0 0.0625em var(--primary);
	top: -0.0625em;
	left: -0.0625em;
	width: calc(100% + 0.125em);
	height: calc(100% + 0.125em);
}
input[type="radio"]:after {
	background-color: hsl(0,0%,100%);
	top: 25%;
	left: 25%;
	width: 50%;
	height: 50%;
}
input[type="radio"]:checked:before,
input[type="radio"]:checked:after {
	transform: scale(1);
}
label:has(input[type="radio"]:checked),
label:has(input[type="radio"]:not(:checked)):hover {
	background-color: hsla(0,0%,100%,0.5);
}
.selection {
	border-radius: 0.75em;
	box-shadow: 0 0 0 0.125em var(--primary) inset;
	display: none;
	pointer-events: none;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 4.5em;
	transition: transform var(--trans-dur) var(--trans-timing);
}
label:has(input[type="radio"]:checked) ~ .selection {
	animation: fade-in var(--trans-dur) var(--trans-timing);
	display: inherit;
}
label:nth-of-type(2):has(input[type="radio"]:checked) ~ .selection {
	transform: translateY(100%);
}
label:nth-of-type(3):has(input[type="radio"]:checked) ~ .selection {
	transform: translateY(200%);
}
.truncate {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* Dark theme */
@media (prefers-color-scheme: dark) {
	:root {
		--bg: hsl(var(--hue),90%,10%);
		--fg: hsl(var(--hue),90%,90%);
	}
	label {
		background-color: hsla(var(--hue),90%,30%,0);
	}
	label:has(input[type="radio"]:checked),
	label:has(input[type="radio"]:not(:checked)):hover {
		background-color: hsla(var(--hue),90%,30%,0.5);
	}
	input[type="radio"] {
		background-color: hsl(var(--hue),90%,30%);
		box-shadow:
			0 0 0 0.0625em hsl(var(--hue),90%,50%),
			0 0.125em 0.125em 0.0625em hsla(var(--hue),90%,10%,0.3);
	}
}

/* Animations */
@keyframes fade-in {
	from { opacity: 0; }
	to { opacity: 1; }
}