
:root {

  /**
   * COLORS
   */

  --gold-crayola: hsl(38, 61%, 73%);
  --quick-silver: hsla(0, 0%, 65%, 1);
  --davys-grey: hsla(30, 3%, 34%, 1);
  --smoky-black-1: hsla(40, 12%, 5%, 1);
  --smoky-black-2: hsla(30, 8%, 5%, 1);
  --smoky-black-3: hsla(0, 3%, 7%, 1);
  --eerie-black-1: hsla(210, 4%, 9%, 1);
  --eerie-black-2: hsla(210, 4%, 11%, 1);
  --eerie-black-3: hsla(180, 2%, 8%, 1);
  --eerie-black-4: hsla(0, 0%, 13%, 1);
  --white: hsla(0, 0%, 100%, 1);
  --white-alpha-20: hsla(0, 0%, 100%, 0.2);
  --white-alpha-10: hsla(0, 0%, 100%, 0.1);
  --black: hsla(0, 0%, 0%, 1);
  --black-alpha-80: hsla(0, 0%, 0%, 0.8);
  --black-alpha-15: hsla(0, 0%, 0%, 0.15);

  /**
   * GRADIENT COLOR
   */

  --loading-text-gradient: linear-gradient(90deg, transparent 0% 16.66%, var(--smoky-black-3) 33.33% 50%,  transparent 66.66% 75%);
  --gradient-1: linear-gradient(to top,hsla(0, 0%, 0%, 0.9),hsla(0, 0%, 0%, 0.7),transparent);

  /**
   * TYPOGRAPHY
   */

  /* font-family */
  --fontFamily-forum: 'Forum', cursive;
  --fontFamily-dm_sans: 'DM Sans', sans-serif;

  /* font-size */
  --fontSize-display-1: calc(1.3rem + 6.7vw);
  --fontSize-headline-1: calc(2rem + 2.5vw);
  --fontSize-headline-2: calc(1.3rem + 2.4vw);
  --fontSize-title-1: calc(1.6rem + 1.2vw);
  --fontSize-title-2: 2.2rem;
  --fontSize-title-3: 2.1rem;
  --fontSize-title-4: calc(1.6rem + 1.2vw);
  --fontSize-body-1: 2.4rem;
  --fontSize-body-2: 1.6rem;
  --fontSize-body-3: 1.8rem;
  --fontSize-body-4: 1.6rem;
  --fontSize-label-1: 1.4rem;
  --fontSize-label-2: 1.2rem;

  /* font-weight */
  --weight-regular: 400;
  --weight-bold: 700;

  /* line-height */
  --lineHeight-1: 1em;
  --lineHeight-2: 1.2em;
  --lineHeight-3: 1.5em;
  --lineHeight-4: 1.6em;
  --lineHeight-5: 1.85em;
  --lineHeight-6: 1.4em;

  /* letter-spacing */
  --letterSpacing-1: 0.15em;
  --letterSpacing-2: 0.4em;
  --letterSpacing-3: 0.2em;
  --letterSpacing-4: 0.3em;
  --letterSpacing-5: 3px;

  /**
   * SPACING
   */

  --section-space: 70px;

  /**
   * SHADOW
   */

  --shadow-1: 0px 0px 25px 0px hsla(0, 0%, 0%, 0.25);

  /**
   * BORDER RADIUS
   */

  --radius-24: 24px;
  --radius-circle: 50%;

  /**
   * TRANSITION
   */

  --transition-1: 250ms ease;
  --transition-2: 500ms ease;
  --transition-3: 1000ms ease;

}







*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

li { list-style: none; }

a,
img,
data,
span,
input,
button,
select,
ion-icon,
textarea { display: block; }

a {
  color: inherit;
  text-decoration: none;
}

img { height: auto; }

input,
button,
select,
textarea {
  background: none;
  border: none;
  font: inherit;
}

input,
select,
textarea {
  width: 100%;
  outline: none;
}

button { cursor: pointer; }

address { font-style: normal; }

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--eerie-black-1);
  color: var(--white);
  font-family: var(--fontFamily-dm_sans);
  font-size: var(--fontSize-body-4);
  font-weight: var(--weight-regular);
  line-height: var(--lineHeight-5);
  overflow: hidden;
  height: 100vh;
}

body.loaded { overflow: overlay; }

body.nav-active { overflow: hidden; }

::-webkit-scrollbar { width: 5px; }

::-webkit-scrollbar-track { background-color: transparent; }

::-webkit-scrollbar-thumb { background-color: var(--gold-crayola); }


.preload {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--gold-crayola);
    z-index: 10;
    display: grid;
    place-content: center;
    justify-items: center;
    transition: var(--transition-2);
  }
  
  .preload > * { transition: var(--transition-1); }
  
  .preload.loaded > * { opacity: 0; }
  
  .preload.loaded {
    transition-delay: 250ms;
    transform: translateY(100%);
  }
  
  
  .preload .text {
    background-image: linear-gradient(45deg,blue,transparent);
    background-size: 500%;
    font-size: calc(2rem + 3vw);
    font-weight: var(--weight-bold);
    margin-top: 1em;
    line-height: 1em;
    text-transform: uppercase;
    letter-spacing: 16px;
    padding-inline-start: 16px;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-stroke: 0.5px var(--eerie-black-3);
    animation: loadingText linear 2s infinite;
  }
  
  @keyframes loadingText {
    0% { background-position: 100%; }
    100% { background-position: 0%; }
  }
  
  
  svg {
    width: 240px;
    height: 100px;
    margin: auto;
  }
  
  svg #plug,
  svg #socket {
    fill:var(--eerie-black-1);
  }
  
  svg #loop-normal {
    fill: none;
    stroke: blue;
    stroke-width: 12;
  }
  
  svg #loop-offset {
    display: none;
  }