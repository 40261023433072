section.player-name{
    position: relative;
    background: white;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.player-name svg{
    position: absolute;
    height: auto;
    width: 100px;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.player-name form{

    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 0;

    margin: auto;
    width: 90%;
    background: rgba(255, 255, 255, 0.4); 
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    position: relative;
    color: white;
    z-index: 1;
    backdrop-filter: blur(10px);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.player-name form .name-field{
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    gap: 15px;
    padding: 0 10px;
}

form .buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    width: 100%;
}

form .buttons i.name-icon{
    font-size: 25px;
    color: black;
    cursor: pointer;
}

.name-field span{
    display: none;
}

.buttons button.submit-button{
    width: 50%;
}

.name-field h1{
    margin: 0;
    color: blue;
}

.groupe-number span.MuiFormLabel-asterisk{
    display: none;
}

.groupe-number{
    width: 100%;
    padding: 0 10px;
    display: grid;
    grid-template-columns: 2.5fr 1fr;
}


@media screen and (min-width: 500px) {
    section.player-name {
        display: none;
    }
}

.groupe-number .groupe-radio{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: black;
}

.groupe-radio .group-content{
    width: 100%;
    height: 20px;
    display: flex;
    align-items: center;
}

.group-content input{
    margin-left: 10px;
    height: 15px;
    width: 15px;
}

.group-content label{
    padding: 0;
}

.name-field h1{
    font-size: 1.5rem;
}