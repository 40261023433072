@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900&display=swap');

:root {
  --family: 'Nunito', sans-serif;
}


#card{
    margin-top: 100px;
}

.admin-container h1.text{
    font-size: 2em;
    font-weight: 800;
    color: azure;
    text-align: center;
    margin-bottom: 40px;
}
section.yo {
  font-size: 17px;
  color: #666;
  text-align: justify;
  font-family: var(--family);
  background: linear-gradient(326deg, rgba(147, 13, 224, 1) 0%, rgba(0, 159, 231, 1) 15%, rgba(0, 222, 223, 1) 50%, rgba(156, 0, 224, 1) 100%);
  background-size: cover;
  min-height: 100vh;
  height: fit-content;
  padding: 50px 0;
}

body{
    overflow-y: scroll;
}

.card-body{
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-height: 340px;
}

.card-body .image img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 15%;
    border-radius: 10px 0 0 10px
}

.card-body .image{
    height: 100%;
    width: 100%;
    max-height: 340px;
    border-radius: 10px 0 0 10px;
}

.card-body .info{
    padding: 30px;
}


.card {
  margin: auto;
  width: 90%;
  max-width: 1000px;
  /* background: rgba(255, 255, 255, 0.4); */
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  position: relative;
  color: white;
  z-index: 1;
  backdrop-filter: blur(10px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

.card-pic {
  width: 12rem;
  background-blend-mode: hard-light;
  margin: -20vw auto;
}




  .card-pic {
    position: absolute;
    left: -6vw;
    top: -5vw;
    margin: 0;
  }

h5 {
  font-size: 1.8rem;
  font-weight: bold;
}

h5::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f004";
  color: #5CF5CB;
}

.line {
  width: 100%;
  height: 4px;
  background-color: #5CF5CB;
  opacity: 0.7;
  margin-bottom: 10px;
}

i.fas {
  position: absolute;
  right: -2vw;
  bottom: -3vw;
  z-index: 2;
  color: blue;
  font-size: 3rem;
  /* background: rgba(255, 255, 255, 0.5);  */
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  backdrop-filter: blur(15px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
}

@keyframes SlowFloat {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-10px);
  }
}

.slowfloat2s {
  animation: SlowFloat 2s infinite alternate;
}

.slowfloat3s {
  animation: SlowFloat 3s infinite alternate;
}

.admin-container svg{
    position: absolute;
    left:0;
    top: 0;
}

.info span{
    display: inline;
    color: beige;
    font-weight: 600;
}

h1.card-text{
    margin-top: 20px;
    color: blue;
    font-weight: 800;
    font-size: 1.2rem;
}

@media screen and (max-width: 600px) {
  section.yo {
      visibility: hidden;
  }
}

section.yo svg{
  height: auto;
  width: 20%;
  max-width: 200px;
}

section.yo .admin-container i.bx-reset,
section.yo .admin-container i.bx-refresh,
section.yo .admin-container i.bxs-share,
.repart-results,
.repart-results i{
  font-size: 1.5rem;
  color: aliceblue;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 2;
  background: rgba(255, 255, 255, 0.5);  
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 0.5rem;
  border-radius: 10px;
  transition: all 0.6s ease-in-out;
  backdrop-filter: blur(15px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
i.bx-reset:is(:active),
i.bx-refresh:is(:active),
.repart-results i:is(:active){
  transform: scale(0.8);
}

section.yo .admin-container i.bx-refresh{
  top: 80px;
}

section.yo .admin-container i.bxs-share{
  right: 80px;
}

.repart-results{
  display: flex;
  flex-direction: column;
  align-items: center;
  
  top: 0;
  right: 0;
  border-radius: 0;
  visibility: hidden;
  height: 100vh;
  width: 100vw;
  padding: 20px;
  transition: all 0.5s ease;
}

.repart-results.visible{
  visibility: visible;
}

.repart-results h2{
  text-align: center;
  color: blue;
}

.repart-results .group-result{
  margin-top: 50px;
  display: grid;
  max-width: 1000px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 40px;
}

.group-repart{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 20px;
}

.group-repart h3{
  text-align: center;
  grid-column: 1/-1;
  font-size: 1.5rem;
  color: black;
}

.repart-results i{
  font-size: 2rem;
  color: red;
  top: 20px;

}
